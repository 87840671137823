import { extend, localize, configure } from "vee-validate";
import { min, required, email, between, min_value, max_value, numeric, alpha, max, confirmed, image, dimensions, alpha_spaces, regex } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

// Custom rule for decimal validation
extend("decimal", {
  validate(value) {
    // Regular expression to match decimal numbers
    const decimalRegex = /^[+-]?\d+(\.\d+)?$/;
    return decimalRegex.test(value);
  },
  message: "The {_field_} field must be a valid decimal number"
});

// Install rules
extend("required", required);
extend("min", min);
extend("email", email);
extend("between", between);
extend("min_value", min_value);
extend("max_value", max_value);
extend("numeric", numeric);
extend("alpha", alpha);
extend("max", max);
extend("confirmed", confirmed);
extend("image", image);
extend("dimensions", dimensions);
extend("alpha_spaces", alpha_spaces);
extend("regex", regex);

// Install classes
configure({
  classes: {
    invalid: 'is-invalid'
  }
})

// Install messages
localize({
  en
});