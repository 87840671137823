import {
  EventService,
  PaymentServie
} from "@/common/api.service";

import {
  GET_TYPE_CATEGORY,
  SET_TYPE_CATEGORY,
  ADD_EVENT_LOCATION,
  SET_CREATED_EVENT,
  EVENT_SCHEDULE,
  PUBLISH_EVENT,
  FETCH_EVENT,
  CREATE_EVENT,
  GET_ATTENDEE_FORM,
  CREATE_ATENDEE,
  UPDATE_ATENDEE,
  EVENT_DETAIL,
  PUBLISHED_EVENT_DETAIL,
  PRIVATE_EVENT_DETAIL,
  UPDATE_EVENT,
  FETCH_EVENT_BY_USER,
  SHARE_EVENT,
  EVENT_BOOKING,
  SET_DEFAULT_CURRENCY,
  SET_TICKET_SUMMARY,
  EVENT_LISTVIEW,
  EXORT_EVENT_REPORT,
  ORDERTRANSACTION,
  NOTIFYBYEMAIL,
  PAYMENTCREATE,
  UNPUBLISH_EVENT,
  SAVE_EVENT,
  DELETE_EVENT,
  GET_ALL_ORGANIZATION_LIST,
  FETCH_EVENT_REPORT,
  REFUND_AMOUNT,
  VERIFY_REFUND_EMAIL,
  REFUND_OTP,
  GET_PURCHASED_TICKET,
  SEND_INVITE_EVENT,
  RESEND_INVITE_EVENT,
  POST_ATTENDEE_FORM,
  NOTIFY_ATTENDEE,
  GET_DIETARY,
  ADD_DIETARY_OPTION,
  UPDATE_DIETARY,
  DELETE_DIETARY,
  EXPORT_DEFAULT_TICKET_CSV,
  IMPORT_TICKET_CSV,
  FETCH_ATTENDEE_REPORT,
  EXORT_ATTENDEE_REPORT,
  CRETAE_ACTIVITY_LOG,
  DOWNLOAD_TICKET,
  EVENT_REPORT_VIEW,
  EXPRESS_TICKET_DOWNLOAD,
  EVENT_POSTPONE,
  EXORT_TRANSACTION_REPORT,
  GET_TRANSACTIONS,
  EVENT_RECURRING_PASSES,
  EVENT_RECURRING_PASSES_DETAILS,
  UPCOMING_EVENT_REPORT,
  SEND_EVENT_ALERT_TO_ATTENDEE,
  GET_NORIFICATION_LOGS
} from "./actions.type";
import { ISLOADING, 
  ISBUTTONLOADING, 
  SET_ERROR, 
  SET_FETCH_EVENT, 
  SET_FETCH_EVENT_REPORT,
  SET_FETCH_EVENT_BY_USER, 
  MERGE_EVENTS, 
  MERGE_EVENTS_ALL, 
  MERGE_EVENT_REPORTS_ALL,
  SET_EVENT_DETAIL,
  TICKET_SUMMARY
} from "./mutations.type";

const state = {
  events: {},
  eventsForUser: [],
  errors: null,
  getTypeCategory: {},
  createdEvent: {},
  getEventDetail: {},
  getTicketsDetails: {}
};

const getters = {
  events(state) {
    return state.events;
  },
  eventsForUser(state) {
    return state.eventsForUser;
  },
  getTypeCategory(state){
    return state.getTypeCategory;
  },
  createdEvent(state){
    return state.createdEvent;
  },
  getEventDetail(state){
    return state.getEventDetail;
  },
  getTicketsDetails(state){
    return state.getTicketsDetails;
  }
};

const actions = {

  async [GET_ALL_ORGANIZATION_LIST](context, payloadId) {
    EventService.init();
    EventService.setHeader();
    const { data } = await EventService.get(`organization-list/${payloadId}`);
    return data;
  },

  async [CREATE_EVENT](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/basics`, payload)
      .then(({ data }) => {
        context.commit(SET_CREATED_EVENT, data.data);
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [UPDATE_EVENT](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.uuid}/basics`, payload.formData)
      .then(({ data }) => {
        context.commit(SET_CREATED_EVENT, data.data);
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [ADD_EVENT_LOCATION](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    let params = {
      "location": payload.location.toLowerCase(),
      "venue": payload.venue
    }
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_uuid}/location`, params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [EVENT_SCHEDULE](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_uuid}/schedule`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [PUBLISH_EVENT](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/toggle-publish`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        if(response.data.errors){
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        }else{
          context.commit(SET_ERROR, response.data.message);
          resolve(response);
        }
      });
    });
  },
  async [GET_TYPE_CATEGORY](context) {

    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    
    const { data } = await EventService.get(`resources`);
    
    let timezone = [];
    for (let times in data.data.timezones) {
      timezone.push({id: times, name: data.data.timezones[times]})
    }
    data.data.timezones = timezone
    context.commit(SET_TYPE_CATEGORY, data.data);
    context.commit(ISLOADING, false);
    return data;
  },
  async [EVENT_DETAIL](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    if(payload){
      const { data } = await EventService.get(`events/details/${payload}`);
      context.commit(ISLOADING, false);
      context.commit(SET_CREATED_EVENT, data.data);
      return data;
    }else{
      context.commit(ISLOADING, false);
      context.commit(SET_CREATED_EVENT, {});
    }
  },
  async [FETCH_EVENT_BY_USER](context, payload) {
    EventService.init();
    
    context.commit(ISLOADING, true);
    context.commit(ISBUTTONLOADING, true);
    // if(payload.isLoadMore == true){
    //   payload.currentPage = payload.currentPage + 1;
    // }
    let query = `?perPage=${payload.perPage}&page=${payload.currentPage}&mode=${payload.mode}`
    if (payload.search) {
      query += `&search=${payload.search}`;
    }
    if (payload.filter) {
      query += `&filter=${payload.filter}`;
    }
    if (payload.is_user_events) {
      query += `&is_user_events=${payload.is_user_events}`;
    }
    const { data } = await EventService.get(`events`, query);

    if(payload.isLoadMore){
      context.commit(MERGE_EVENTS, data.data);
    }else{
      context.commit(SET_FETCH_EVENT_BY_USER, data.data);
    }
    context.commit(ISLOADING, false);
    context.commit(ISBUTTONLOADING, false);
    return data;
  },


  async [EXORT_EVENT_REPORT](context,payload) {
    let domain = location.origin;
    if (domain.includes('localhost')) {
      domain = "https://events.angelcommunities.net/api/";
    } else {
      domain += '/api/';
      domain = domain.replace('angelcommunities', 'events.angelcommunities')
    }
    
    let url = domain + `exportReport?${payload.eventId ? `eventId=${payload.eventId}` : ''}${payload.ticketId && payload.ticketId !== null ? `&ticketId=${payload.ticketId}`: ''}${payload.status && payload.status !== null ? `&status=${payload.status}`: ''}`;
    if (!payload.eventId && payload.userId) {
      url = url + '&user_id=' + payload.userId;
    }
    if (payload.search) {
      url = url + `&search=${payload.search && payload.search !== null ? payload.search : null}`;
    }
    if (payload.transaction_id) {
      url = url + `&transactionId=${payload.transaction_id && payload.transaction_id !== null ? payload.transaction_id : null}`;
    }
    if(payload.isPDF){
      url = url + '&isPDF=true';
    }
    const link = document.createElement('a');
    link.href = url;
    
    if(payload.isPDF){
      link.setAttribute('download', 'file.pdf');
    } else {
      link.setAttribute('download', 'file.xlsx'); //or any other extension
    }
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },

  async [EVENT_LISTVIEW](context,payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events`, `${payload.filter}`);
    context.commit(ISLOADING, false);
    return data;
  },

  async [FETCH_EVENT](context,payload) {
    if(payload.page == 1){
      context.commit(ISLOADING, true);
    }
    EventService.init();
    context.commit(ISBUTTONLOADING, true);
    var query = ''
    if(payload){
      query = `?perPage=${payload.perPage}&page=${payload.page==undefined ? payload.page=1 : payload.page}&${payload.query}`;
    }
    const { data } = await EventService.get(`events/all`,query);
    if(payload.page != 1){
      context.commit(MERGE_EVENTS_ALL, data.data);
    }else{
      context.commit(SET_FETCH_EVENT, data.data);
    }
    context.commit(ISBUTTONLOADING, false);
    context.commit(ISLOADING, false);
    return data;
  },
  async [FETCH_EVENT_REPORT](context,payload) {
    if(payload.page == 1){
      context.commit(ISLOADING, true);
    }
    EventService.init();
    context.commit(ISBUTTONLOADING, true);
    var query = ''
    if(payload){
      // if (payload.eventId) {
      //   query += payload.eventId;
      // }
      query = `?perPage=20`;
      if (payload.eventId) {
        query = query + '&eventId=' + payload.eventId;
      }
      if (payload.ticketId) {
        query = query + '&ticketId=' + payload.ticketId;
      }
      query = query + `&page=${payload.page==undefined ? 1 : payload.page}`;
      if (payload.status) {
        query = query + `&status=${payload.status && payload.status !== null ? payload.status : null}`;
      }
      if (payload.search) {
        query = query + `&search=${payload.search && payload.search !== null ? payload.search : null}`;
      }
      if (payload.transaction_id) {
        query = query + `&transactionId=${payload.transaction_id && payload.transaction_id !== null ? payload.transaction_id : null}`;
      }
    }
    
    const { data } = await EventService.get(`events/report${query}`);
    if(payload.page != 1){
      // context.commit(SET_FETCH_EVENT_REPORT, data.data);
      context.commit(MERGE_EVENT_REPORTS_ALL, data.data);
    }else{
      context.commit(SET_FETCH_EVENT_REPORT, data.data);
    }
    context.commit(ISBUTTONLOADING, false);
    context.commit(ISLOADING, false);
    return data;
  },
  async [CREATE_ATENDEE](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.create(`events/${payload}/attendee-form`);
    context.commit(ISLOADING, false);
    context.commit(SET_FETCH_EVENT, data.data);
    return data;
    
  },
  async [SEND_EVENT_ALERT_TO_ATTENDEE](context,payload) {
    EventService.init();
    //context.commit(ISLOADING, true);
    const { data } = await EventService.create(`events/attendees/sendEventNotifications`, payload);
    //context.commit(ISLOADING, false);
    context.commit(SET_FETCH_EVENT, data.data);
    return data;
    
  },
  async [GET_NORIFICATION_LOGS](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/attendees/notification/logs/${payload.uuid}?page=${payload.page}&perPage=${payload.perPage}`);
    context.commit(ISLOADING, false);
    context.commit(SET_FETCH_EVENT, data.data);
    return data;
    
  },
  async [GET_ATTENDEE_FORM](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/${payload}/attendee-form`);
    context.commit(ISLOADING, false);
    context.commit(SET_FETCH_EVENT, data.data);
    return data;
    
  },
  async [UPDATE_ATENDEE](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.patch(`events/${payload.uuid}/attendee-form`, payload.payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [PUBLISHED_EVENT_DETAIL](context, payload) {
      EventService.init();
      context.commit(ISLOADING, true);
      const { data } = await EventService.get(`events/event-details/${payload}`);
      context.commit(SET_EVENT_DETAIL, data.data);
      context.commit(ISLOADING, false);
      return data;
  },
  async [PRIVATE_EVENT_DETAIL](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/private/${payload}`);
    context.commit(SET_EVENT_DETAIL, data.data);
    context.commit(ISLOADING, false);
    return data;
  },
  async [SHARE_EVENT](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.create(`events/${payload.event_uuid}/share`, payload.params);
    context.commit(SET_FETCH_EVENT, data.data);
    context.commit(ISLOADING, false);
    return data;
  },
  async [EVENT_BOOKING](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/checkout`, payload.params)
      .then((data) => {
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [REFUND_AMOUNT](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`orders/${payload.transactionid}/refund`,payload.body)
      .then((data) => {
        context.commit(SET_ERROR, '');
        resolve(data);
        context.commit(ISLOADING, false);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [VERIFY_REFUND_EMAIL](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/verifyOTP`,payload)
      .then((data) => {
        context.commit(SET_ERROR, '');
        resolve(data);
        context.commit(ISLOADING, false);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [REFUND_OTP](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.create(`events/sendOTP/${payload.tid}`, payload.body);
    context.commit(SET_EVENT_DETAIL, data.data);
    context.commit(ISLOADING, false);
    return data;
  },

  async [SET_DEFAULT_CURRENCY](context, payload) {
    context.commit(ISBUTTONLOADING, true);
    EventService.init();
    EventService.setHeader();
    
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_uuid}/currency`, payload.params)
      .then(({ data }) => {
        context.commit(SET_ERROR, '');
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },

  async [SET_TICKET_SUMMARY](context,payload) {
    context.commit(TICKET_SUMMARY, payload);
  },

  async [ORDERTRANSACTION](context, payload) {
    EventService.init();
    return new Promise(resolve => {
      EventService.withoutTokenPatch(`order/${payload.transactionid}/${payload.status}/${payload.paymentReference}`)
      .then((data) => {
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [PAYMENTCREATE](context, payload) {
    context.commit(ISLOADING, true);
    PaymentServie.init();
    return new Promise(resolve => {
      PaymentServie.create(`payment/create`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [NOTIFYBYEMAIL](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/notify`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [SAVE_EVENT](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload}/saveForLater`, {
        "visibility": "private"
      })
      .then((data) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({response}) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  async [UNPUBLISH_EVENT](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload}/switchToDraft`)
      .then((data) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({response}) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  async [DELETE_EVENT](context,payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.destroy(`events/${payload}/delete`)
      .then((data) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({response}) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },
  async [GET_PURCHASED_TICKET](context, payload) {
    EventService.init();
    //EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`getTicketList`, payload)
      .then((data) => {
        resolve(data);
        context.commit(ISLOADING, false);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [SEND_INVITE_EVENT](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`sendInviteForEvent`, payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({response}) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },
  async [RESEND_INVITE_EVENT](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`reSendInviteForEvent`, payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch((response) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  async [DOWNLOAD_TICKET](context, payload) {
    let domain = location.origin;
    if (domain.includes('localhost')) {
      domain = "https://events.angelcommunities.net/api/";
    } else {
      domain += '/api/';
      domain = domain.replace('angelcommunities', 'events.angelcommunities')
    }

    let url = domain + `events/attendee/ticket/download${payload.transactionId}${payload.attendeeId}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ticket.pdf'); //or any other extension
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },

  async [EXPRESS_TICKET_DOWNLOAD](context, payload) {
    let domain = location.origin;
    if (domain.includes('localhost') || domain.includes('192.168')) {
      domain = "https://events.angelcommunities.net/api/"; 
    } else {
      domain += '/api/';
      domain = domain.replace('angelcommunities', 'events.angelcommunities')
    }
    let url = domain + `events/attendee/expressTicket/download/${payload}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ticket.pdf'); //or any other extension
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },
  

  async [POST_ATTENDEE_FORM](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`storeAttendee/${payload.event_id}`, payload.params)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  
  async [NOTIFY_ATTENDEE](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/notifyAttendee`, payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [GET_DIETARY](context,payload) {
    EventService.init();
    EventService.setHeader();
    if(payload){
      const data  = await EventService.get(`/${payload}/dietary-info`);
      return data;
    }
  },

  async [ADD_DIETARY_OPTION](context, payload) {
    EventService.init();
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_id}/dietary-info`, payload.params)
      .then((data) => {
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [UPDATE_DIETARY](context, payload) {
    EventService.init();
    EventService.setHeader();
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_id}/dietary-info/${payload.id}`, payload.params)
      .then(( data ) => {
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [DELETE_DIETARY](context,payload) {
    EventService.init();
    //context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.destroy(`events/${payload.event_id}/dietary-info/${payload.id}`)
      .then((data) => {
        ///context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({response}) => {
        //context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  // IMPORT-EXPORT TICKET CSV
  async [EXPORT_DEFAULT_TICKET_CSV](context) {
    EventService.init();
    context.commit(ISBUTTONLOADING, true);
    const { data } = await EventService.get(`downloadSampleCSV`);
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
    anchor.target = '_blank';
    anchor.download = 'sample-ticket.csv';
    anchor.click();
    context.commit(ISBUTTONLOADING, false);
  },

  async [IMPORT_TICKET_CSV](context, payload) {
    EventService.init();
    EventService.setHeader(); 
    return new Promise(resolve => {
      var formData = new FormData();
      formData.append('offline_tickets_csv', payload.offline_tickets_csv);
      EventService.create(`events/${payload.event_id}/offlineTicketImport/${payload.ticket_id}`, formData)
        .then((data) => {
          ///context.commit(ISLOADING, false);
          resolve(data);
        })
        .catch(({response}) => {
          //context.commit(ISLOADING, false);
          resolve(response);
        });
    });
  },

  //Event Report Api
  async [EVENT_REPORT_VIEW](context,payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/report-log`, `${payload.filter}${payload.event_id}`);
    context.commit(ISLOADING, false);
    return data;
  },

  // ATTENDEE_REPORT
  async [FETCH_ATTENDEE_REPORT](context,payload) {
    if(payload.page == 1){
      context.commit(ISLOADING, true);
    }
    EventService.init();
    context.commit(ISBUTTONLOADING, true);
    var query = ''
    if(payload){
      query = `?perPage=20`;
      if (payload.eventId) {
        query = query + '&eventId=' + payload.eventId;
      }
      if (payload.ticketId) {
        query = query + '&ticketId=' + payload.ticketId;
      }
      query = query + `&page=${payload.page==undefined ? 1 : payload.page}`;
      if (payload.status) {
        query = query + `&attendeeStatus=${payload.status && payload.status !== null ? payload.status : null}`;
      }
      if (payload.search) {
        query = query + `&search=${payload.search && payload.search !== null ? payload.search : null}`;
      }
    }
    
    const { data } = await EventService.get(`events/attendee/report${query}`);
    if(payload.page != 1){
      context.commit(MERGE_EVENT_REPORTS_ALL, data.data);
    }else{
      context.commit(SET_FETCH_EVENT_REPORT, data.data);
    }
    context.commit(ISBUTTONLOADING, false);
    context.commit(ISLOADING, false);
    return data;
  },

  async [EXORT_ATTENDEE_REPORT](context,payload) {
    let domain = location.origin;
    if (domain.includes('localhost')) {
      domain = "https://events.angelcommunities.net/api/";
    } else {
      domain += '/api/';
      domain = domain.replace('angelcommunities', 'events.angelcommunities')
    }
    
    let url = domain + `events/attendee/report/download?${payload.eventId ? `eventId=${payload.eventId}` : ''}${`&user_id=${payload.userId}`}${payload.status && payload.status !== null ? `&attendeeStatus=${payload.status}`: ''}`;
    if(payload.isPDF){
      url = url + '&isPDF=true';
    }
    if (payload.search) {
      url = url + `&search=${payload.search && payload.search !== null ? payload.search : null}`;
    }
    const link = document.createElement('a');
    link.href = url;
    if(payload.isPDF){
      link.setAttribute('download', 'file.pdf');
    } else {
      link.setAttribute('download', 'file.xlsx'); //or any other extension
    }
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },

  //activity log api 
  async [CRETAE_ACTIVITY_LOG](context, payload) {
    context.commit(ISLOADING, true);
    PaymentServie.init();
    return new Promise(resolve => {
      PaymentServie.create(`payment/create/activitylog`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  //activity log api 
  async [CRETAE_ACTIVITY_LOG](context, payload) {
    context.commit(ISLOADING, true);
    PaymentServie.init();
    return new Promise(resolve => {
      PaymentServie.create(`payment/create/activitylog`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  // EVENT Postpond
  async [EVENT_POSTPONE](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.put(`events/${payload.uuid}/updateStatus`,payload.data)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  
  async [EXORT_TRANSACTION_REPORT](context,payload) {
    let domain = location.origin;
    if (domain.includes('localhost')) {
      domain = "https://events.angelcommunities.net/api/";
    }
    else {
      domain += '/api/';
      domain = domain.replace('angelcommunities', 'events.angelcommunities')
    }
    let params = '';
    if(payload.eventId){
      params = `event_id=${payload.eventId}`;
    }
    
    let url = domain + `export/transactions?${params}`;

    if (params !== '' && payload.userId) {
      url = url + '&user_id=' + payload.userId;
    } else {
      url = url + 'user_id=' + payload.userId;
    }

    if(payload.start && payload.end){
      url += `&start=${payload.start}&end=${payload.end}&filterBy=true`;
    }

    if(payload.toPDF){
      url = url + '&toPDF=true';
    }else {
      url = url + '&toCSV=true';
    }
    const link = document.createElement('a');
    link.href = url;
    
    if(payload.toPDF){
      link.setAttribute('download', 'file.pdf');
    } else {
      link.setAttribute('download', 'file.xlsx'); //or any other extension
    }
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },

  async [GET_TRANSACTIONS](context,payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);

    const { data } = await EventService.get(`events/transactions${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },

  async [EVENT_RECURRING_PASSES](context,payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);

    const { data } = await EventService.get(`events/passes${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },
  async [EVENT_RECURRING_PASSES_DETAILS](context,payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/passes/${payload}/details`);
    context.commit(ISLOADING, false);
    return data;
  },
  async [UPCOMING_EVENT_REPORT](context, payload) {
    let url = '';
    if (payload.toPDF || payload.toCSV) {
      if(payload.toPDF){
        url = `events/upcoming-report/export?toPDF=true&user_id=${payload.userId}`;
      }else{
        url = `events/upcoming-report/export?toCSV=true&user_id=${payload.userId}`;
      }
      
      let domain = location.origin;
      if (domain.includes('localhost')) {
        domain = "https://events.angelcommunities.net/api/";
      } else {
        domain += '/api/';
        domain = domain.replace('angelcommunities', 'events.angelcommunities')
      }
      url = domain + `${url}`;
      const link = document.createElement('a');
      link.href = url;
      if(payload.toPDF){
        link.setAttribute('download', 'file.pdf');
      } else {
        link.setAttribute('download', 'file.xlsx'); //or any other extension
      }
      link.setAttribute('target', '_blank'); //or any other extension
      document.body.appendChild(link);
      link.click();
      return;

    } else {

      url = `events/upcoming-report`;
      EventService.init();
      EventService.setHeader();
      context.commit(ISLOADING, true);
      const { data } = await EventService.get(`${url}`);
      context.commit(ISLOADING, false);
      return data;
      
    }
  },

};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_TYPE_CATEGORY](state, typeCategory) {
    state.getTypeCategory = typeCategory;
  },
  [SET_CREATED_EVENT](state, data) {
    state.createdEvent = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FETCH_EVENT_BY_USER](state, events) {
    state.eventsForUser = events;
  },
  [SET_EVENT_DETAIL](state, detail){
    state.getEventDetail = detail
  },
  [MERGE_EVENTS](state, events) {
    state.eventsForUser = state.eventsForUser.concat(events);
  },
  [SET_FETCH_EVENT](state, events) {
    state.events = events;
  },
  [SET_FETCH_EVENT_REPORT](state, events) {
    state.eventReports = events;
  },
  [MERGE_EVENTS_ALL](state, event) {
    state.events = state.events.concat(event);
  },
  [MERGE_EVENT_REPORTS_ALL](state, event) {
    state.eventReports = state.eventReports.concat(event);
  },
  [TICKET_SUMMARY](state, details) {
    state.getTicketsDetails = details;
  }
  
};

export default {
  state,
  getters,
  actions,
  mutations
};
